<template>
  <router-view/>
 </template>
 
 <script setup>
   import { watch, onMounted } from 'vue';
   import {useI18n} from 'vue-i18n'
   const { locale, t } = useI18n();
 
 
   const ApplyLangue = ()=>{
     if(!localStorage.getItem("lngAb")){
          localStorage.setItem("lngAb", 'Fr');
      }else{
        if(locale.value == "Ar"){
          document.body.style.direction = "rtl"
        }else if (locale.value == "Fr"){
          document.body.style.direction = "ltr"
        }
      }
   }
 
   watch(locale,(ov,nv)=>{

    $(document).ready(function() {
      var links = $('.navbar ul li a');
      $.each(links, function (key, va) {
        if (va.href == document.URL) {
          $(this).addClass('active');
        }
      });
      $('.active').closest('li.dropdown').addClass('active');

      $('.page-scroll a').on('click', function(event) {
				var $anchor = $(this);
				$('html, body').stop().animate({
					scrollTop: $($anchor.attr('href')).offset().top
				}, 1500, 'easeInOutExpo');
				event.preventDefault();
			});

      
			var offset = 200;
			var duration = 500;
			$(window).scroll(function() {
				if ($(this).scrollTop() > offset) {
					$('.back-to-top').fadeIn(400);
				} else {
					$('.back-to-top').fadeOut(400);
				}
			});


      $('.owl-stage').owlCarousel({
				loop: true,
				margin: 0,
				autoplayHoverPause:true,
				autoplay: true,
				nav: true,
				navText: [" <i class='fas fa-chevron-left'></i>", " <i class='fas fa-chevron-right'></i>"],
				dots: true,
				responsive: {
					0: {
						items: 1,
						stagePadding: 0
					},
					767: {
						items: 2,
						stagePadding: 60
					},
					1200: {
						items: 3,
						stagePadding: 120
					},
				}
			});
			
			$(".carousel-4items").owlCarousel({
				nav: true,
				navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
				dots: true,
				margin: 30,
				loop: true,
				autoplay: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
					},
					767: {
						items: 2,
					},
					1200: {
						items: 4,
					},
				}
			});
			$(".carousel-3items").owlCarousel({
				nav: true,
				navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
				dots: true,
				margin: 30,
				loop: true,
				autoplay: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
					},
					767: {
						items: 2,
					},
					1200: {
						items: 3,
					},
				}
			});
			$(".carousel-2items").owlCarousel({
				nav: true,
				navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
				dots: true,
				margin: 30,
				loop: true,
				autoHeight:true,
				autoplay: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
					},
					991: {
						items: 2,
					},
				}
			});
			$(".carousel-1item").owlCarousel({
				nav: true,
				navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
				dots: true,
				margin: 30,
				loop: true,
				autoplay: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
					},									
				}
			});
			  //scroll down to content on menu tabs on mobile only
				if ($(window).width() <= 991) {
					$("#nav-tab a").on("click", function() {
						$('html,body').animate({
								scrollTop: $(".tab-content").offset().top - 100
							},
							'slow');
					});
				};

	
			 // Magnific Popup
				 
			$('.magnific-popup').magnificPopup({
				delegate: 'a', // child items selector, by clicking on it popup will open
				type: 'image',
				overflowY:'scroll',
				gallery: {
				enabled: true
				},				
				titleSrc: function(item) {
				return '<a href="' + item.el.attr('href') + '">' + item.el.attr('title') + '</a>';
				},

				callbacks: {open: function() {$('.fixed-top').css('margin-right', '17px');},close: function() {$('.fixed-top').css('margin-right', '0px');}}
			});	

					
			 //------- Window scroll function -------//
				$(window).on('scroll',function() {

					//Collapse the top bar on scroll
					
					if ($("#main-nav").offset().top >1) {
						$('.top-bar').slideUp({
							duration: 250,
							easing: "easeInOutSine"
						}).fadeOut(120);
					} else {
						$('.top-bar').slideDown({
							duration: 0,
							easing: "easeInOutSine"
						}).fadeIn(120);
					}
					
					var scroll = $(window).scrollTop();
					if (scroll >= 1) {
						$(".navbar-expand-xl").addClass("fixed-top");
					} else {
						$(".navbar-expand-xl").removeClass("fixed-top");
					}
			  
			
				}); // end window scroll

      $(window).on("load",function() {

        // Page Preloader 	
        $("#preloader").fadeOut("slow");

      });

    })
     ApplyLangue()
     if (locale.value == "Ar"){
       document.body.style.direction = "rtl"
     }
     else{
       document.body.style.direction = "ltr"
     }
     })
 
   onMounted(()=>{

    

     ApplyLangue()
           //Effects on scroll
       
       AOS.init({
         
         duration: 1500,
         once: true
       });
       
       //initialize skrollr
       
       skrollr.init({
         forceHeight: false
       });
 
       // disable skrollr if using handheld device
       if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
         skrollr.init().destroy();
       }
 
    })
 
 </script>
 
 
 <style >
 
 </style>